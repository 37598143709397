import React from 'react'
import { AvatarWrapper } from './style'

export function Avatar() {
  return (
    <AvatarWrapper>
      <div className='avatar'>
        <a href='mailto:sup@ceddy.org'>
          <img src='https://www.gravatar.com/avatar/cfd6094081678e8efc4096c323d58a94.jpg?s=500' alt='Skytsunami' />
        </a>
      </div>
    </AvatarWrapper>
  )
}
