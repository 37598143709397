import styled from 'styled-components';

const BannerWrapper = styled.section`
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  align-items: flex-end;
  .mobileShow {display: none;}

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px){ 
    .mobileShow {display: inline;}
  }

  @media (min-width: 991px) {
    min-height: 100vh;
  }

  .image_area {
    @media (max-width: 767px) {
      display: none;
    }
  }
  
`;

export default BannerWrapper;
